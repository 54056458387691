import {LocaleService} from "../../i18n/locale-service";
import {BindingEngine, inject} from "aurelia-framework";

@inject(BindingEngine, LocaleService)
export class TranslatableService {
    constructor(bindingEngine, localeService) {
        this.localeService = localeService;
        this.bindingEngine = bindingEngine;
        // Initialize the current locale
        this.currentLocale = this.localeService.contentLocale;

        // Observe changes in contentLocale
        this.subscription = this.bindingEngine.propertyObserver(this.localeService, 'contentLocale')
            .subscribe((newLocale) => {
                this.currentLocale = newLocale;
            });
    }

    /**
     * Cleans up the observer subscription to prevent memory leaks.
     */
    dispose() {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    /**
     * Retrieves the translation for the current content locale. Falls back to the first available translation if none exists.
     * @param {Object} val - Object containing translations mapped by locale.
     * @returns {string} - The translated value or an empty string if no translation is available.
     */
    getTranslation(val) {
        if (typeof val !== 'object' || val === null) {
            return val;
        }
        if (val?.[this.currentLocale]) {
            return val[this.currentLocale];
        }
        return Object.values(val || {}).find(v => v != null) || '';
    }
}
